<template lang="pug">
usersmanagment(:interface_name='interface_name')
</template>

<script>
import usersmanagment from '@/components/Usersmanagment';

export default {
	components: {usersmanagment},
	data() {
		return {
			interface_name: 'agency',
		};
	},
	mounted() {
	},
};
</script>
